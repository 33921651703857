import React , {Component} from "react";
import {
  BrowserRouter,
  Route
} from "react-router-dom";

import MyProvider from "./components/MyProvider.jsx";
import MyContext from './components/MyContext.jsx';
import Header from "./components/Header/Header.jsx";
import Login from "./components/Login/Login.jsx";
import Home from "./components/Home/Home.jsx";
import Dashboard from "./components/Dashboard/Dashboard.jsx";
import SampleRequests from "./components/SampleRequest/Orders.jsx";
import SampleRequest from "./components/SampleRequest/NewOrder.jsx";
import Schedules from "./components/Schedules/Schedules.jsx";
import NewSchedule from "./components/Schedules/NewSchedule.jsx";
import ScheduleDetails from "./components/Schedules/ScheduleDetails.jsx";
import PriceList from "./components/PriceList/PriceList";

import RateCalculator from "./components/RateCalculator/RateCalculator.jsx";
import Attachment from "./components/Attachment/Attachment.jsx";

// import Collections from "./components/Collections/Collections.jsx";
// import NewCollection from "./components/Collections/NewCollection.jsx";

/*****Attendance Leave******/

import AttendanceLeave from "./components/AttendanceLeave/AttendanceLeave.jsx";
import LeaveApproval from "./components/AttendanceLeave/LeaveApproval";

/*****Others********/
import Outstanding from "./components/Others/Outstanding.jsx";
import Targets from "./components/Others/Targets.jsx";
import MyVisitFrequency from "./components/Others/Myvisitfrequency.jsx"


import PostDateCheque from "./components/PostDatedCheque/PostDateCheque.jsx";


/*****Travel Allowance********/
import TravelAllowance from "./components/TravelAllowance/TravelAllowance.jsx";
import NewTravelAllowance from "./components/TravelAllowance/NewTravelAllowance.jsx";
import TravelAllowanceApproval from "./components/TravelAllowance/TravelAllowanceApproval";


import SecondarySale from "./components/SecondarySale/SecondarySale";
import NewSecondarySale from "./components/SecondarySale/NewSecondarySale";

/*****Nita*********/
// import NewVisit from "./components/Schedules/NewVisit.jsx";


/*****Nita*********/

/*****Ashish*********/
import SalesOrder from "./components/Sales/Orders.jsx";

/******** WEB REPORTS *******/

import Reports from "./components/Reports/ReportList";
import AllowanceReport from "./components/Reports/AllowanceReport";
import ExeScheduleDeviationReport from "./components/Reports/DeviationReport";
import SampleRequestReport from "./components/Reports/SampleRequestReport";
import VisitFrequencyReport from "./components/Reports/VisitFrequencyReport";


import CreditNotesReport from "./components/Reports/CreditNotesReport";
import SalesReturnReport from "./components/Reports/SalesReturnReport";
import DistributorSalesReport from "./components/Reports/DistributorSalesReport";


/************  WEB REPORTS **************/

/********* Joint Visit  ***********/
import JointVisits from "./components/JointVisit/JointVisits";
import JointVisitDetails from "./components/JointVisit/JointVisitDetails"

/*********   ***********/

/******   ASM,RSM Approval    *********/
import ScheduleApproval from "./components/Schedules/ScheduleApproval.jsx";

/*******  ASM,RSM Approval   *********/
/*****Ashish*********/
import Loading from "./components/Loading/Loading.jsx";
import BottomNavbar from "./components/BottomNavbar/BottomNavbar.jsx";
// import DevTools from "./components/DevTools/DevTools.jsx";
import InDev from "./components/DevTools/InDev.jsx";
import DivisionSwitcher from "./components/DivisionSwitch/DivisionSwitcher.jsx";
import PrivateRoute from "./components/DivisionSwitch/RouteProtector.jsx";
import NewSalesOrder from "./components/Sales/NewSalesOrder.jsx";
class App extends Component{
  render(){
    return (
      <MyProvider> 
        <BrowserRouter>
          <Route path="/" component={Header} />
          <Route exact path="/" component={Login} />
          <Route exact path={window.project_path+"/index.html"} component={Login} />
          <Route exact path="/division" component={DivisionSwitcher} />
          <PrivateRoute exact path="/home" component={Home} />

          <PrivateRoute exact path="/pricelist" component={PriceList}/>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/samplerequests" component={SampleRequests} />
          <PrivateRoute exact path="/samplerequest/new" component={SampleRequest} />
          <PrivateRoute exact path="/samplerequest/edit/:sid" component={SampleRequest} />
          <PrivateRoute exact path="/schedules" component={Schedules} />
          <PrivateRoute exact path="/schedules/new" component={NewSchedule} />
          <PrivateRoute exact path="/schedules/details/:sched_id" component={ScheduleDetails} />
          <PrivateRoute exact path="/schedules/details/edit/:edit_sched_id" component={ScheduleDetails} />
{/*          
          <PrivateRoute exact path="/collections" component={Collections} />
          <PrivateRoute exact path="/collections/new" component={NewCollection} />
          <PrivateRoute exact path="/collections/edit/:coll_id" component={NewCollection} /> */}
         
          <PrivateRoute exact path="/outstandings" component={Outstanding} />
          <PrivateRoute exact path="/targets" component={Targets} />
          <PrivateRoute exact path="/travel/allowance" component={TravelAllowance} />
          <PrivateRoute exact path="/travel/allowance/new" component={NewTravelAllowance} />
          <PrivateRoute exact path="/travel/allowance/edit/:ta_id" component={NewTravelAllowance} />
          <PrivateRoute exact path="/travel/allowance/approval" component={TravelAllowanceApproval}/>

          
          <PrivateRoute exact path="/employee/attendance/leave" component={AttendanceLeave} />
          <PrivateRoute exact path="/employee/leave/approval" component={LeaveApproval} />

          <PrivateRoute exact path="/secondarysale" component={SecondarySale} />
          <PrivateRoute exact path="/secondarysale/new" component={NewSecondarySale} />

          <PrivateRoute exact path="/rate/calculator" component={RateCalculator} />
          <PrivateRoute exact path="/catalogue/attachment" component={Attachment} />
          {/*****Nita*******/}
          {/* <PrivateRoute exact path="/new/visit" component={NewVisit} /> */}


          {/*****Nita*******/}

      

          {/*****Ashish*******/}
          <PrivateRoute exact path="/myvisitfrequency" component={MyVisitFrequency} />
          <PrivateRoute exact path="/postdatecheque" component={PostDateCheque} />

          {/*****Ashish*******/}

          {/*****************Sales order ***********/}
          <PrivateRoute exact path="/salesorders" component={SalesOrder} />
          <PrivateRoute exact path="/salesorders/new" component={NewSalesOrder} />
          <PrivateRoute exact path="/salesorders/edit/:so_id" component={NewSalesOrder} />


          {/*****************Sales order ***********/}

          { /******   ASM,RSM Approval    *********/ }
          <PrivateRoute exact path="/schedule/approval" component={ScheduleApproval} />

          { /******   ASM,RSM Approval    *********/ }


          {/* REPORTS */}
          <PrivateRoute exact path="/reports" component={Reports} />
          <PrivateRoute exact path="/srreport" component={SampleRequestReport} />
          <PrivateRoute exact path="/visitfrequencyreport" component={VisitFrequencyReport} />
          <PrivateRoute exact path="/allowancereport" component={AllowanceReport} />
          <PrivateRoute exact path="/deviationreport" component={ExeScheduleDeviationReport} />


          <PrivateRoute exact path="/creditnotesreport" component={CreditNotesReport} />
          <PrivateRoute exact path="/salesreturnreport" component={SalesReturnReport} />
          <PrivateRoute exact path="/distributorsalesreport" component={DistributorSalesReport} />

          {/* REPORTS */}

          {/* Joint Visit */}
          <PrivateRoute exact path="/jointvisit" component={JointVisits} />
          <PrivateRoute exact path="/jointvisit/details/:sched_id" component={JointVisitDetails} />
          <PrivateRoute exact path="/jointvisit/details/edit/:edit_sched_id" component={JointVisitDetails} />
          {/* Joint Visit */}
          <Route path="/" component={Loading} />
          <Route path="/" component={BottomNavbar} />
         {/* <Route path="/" component={DevTools} />*/}
        </BrowserRouter>
      </MyProvider>
    );
  }
}

export default App;
App.contextType = MyContext;
