import React , {Component} from "react";
import MyContext from '../MyContext.jsx';
import {Link} from "react-router-dom";
import { CgSearch } from 'react-icons/cg';
import Select from "react-select";
import './Targets.scss';
import DatePicker from "react-datepicker";


class Targets extends Component{
  constructor(props){
    super(props);
    this.state = {
      target_price : '0',
      total_sale  : '0',
      sale_return_total : '0',
      achieved_price : '0',
      pending_price : '0',
      stock_return_total : '0',
      expiry_return_total : '0',
      district:'',
      executive:'',
      districts:[],
      executives:[],
      monthYear:new Date(),
    }
    this.formSubmit = this.formSubmit.bind(this);
    // this.districtSelect = React.createRef();

  }

  componentDidMount(){
    var obj_this = this;
    const url2 = `${process.env.REACT_APP_SSS}/api/getOutstandingDistricts`;
    this.context.loading(1);
    $.ajax({
      url : url2,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         districts: return_data.districts,
         executives :  return_data.executives
        });
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);

    });
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getPrimaryTargets`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        if(return_data.targets){
          if(return_data.targets.target_price>=return_data.achieved_total_sale){
            obj_this.setState({
            target_price : return_data.targets.target_price,
            total_sale : return_data.target_achieved,
            sale_return_total : return_data.sale_return_total,
            achieved_price : return_data.achieved_total_sale,
            expiry_return_total : return_data.expiry_return_total,
            stock_return_total : return_data.stock_return_total,
            pending_price : parseFloat(return_data.targets.target_price - return_data.achieved_total_sale).toFixed(0),
            });
          }
          else{
            obj_this.setState({
            target_price : return_data.targets.target_price,
            total_sale : return_data.target_achieved,
            sale_return_total : return_data.sale_return_total,
            achieved_price : return_data.achieved_total_sale,
            expiry_return_total : return_data.expiry_return_total,
            stock_return_total : return_data.stock_return_total,
            pending_price : 0
          });
          }
          if(!return_data.targets.target_price){
            alert("Target not set");
          }
        }
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      obj_this.context.loading(0);
      alert("Network error");
    });
  }

  districtChange(option){
    this.setState({
      district : option
    });
  }
  executiveChange(option){
    this.setState({
      executive : option
    });
  }
  setMonthYear(option){
    this.setState({
      monthYear :option
    })
  }

  formSubmit(e){
    e.preventDefault();
    $(".targets_page_container tr").css("backgroud-color","");
    // if(!this.state.district.value){
    //   this.districtSelect.current.focus();
    //   return;
    // }

    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getPrimaryTargets`;
    this.context.loading(1);
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        district_id : this.state.district.value,
        exe_staff_id  : this.state.executive.value,
        selected_month  : this.state.monthYear.toLocaleDateString('en-US')
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        if(return_data.targets){
          if(return_data.targets.target_price>=return_data.achieved_total_sale){
            obj_this.setState({
            target_price : return_data.targets.target_price,
            total_sale : return_data.target_achieved,
            sale_return_total : return_data.sale_return_total,
            achieved_price : return_data.achieved_total_sale,
            expiry_return_total : return_data.expiry_return_total,
            stock_return_total : return_data.stock_return_total,
            pending_price : parseFloat(return_data.targets.target_price - return_data.achieved_total_sale).toFixed(0),
            });
          }
          else{
            obj_this.setState({
            target_price : return_data.targets.target_price,
            total_sale : return_data.target_achieved,
            sale_return_total : return_data.sale_return_total,
            achieved_price : return_data.achieved_total_sale,
            expiry_return_total : return_data.expiry_return_total,
            stock_return_total : return_data.stock_return_total,
            pending_price : 0
          });
          }
          if(!return_data.targets.target_price){
            alert("Target not set");
          }
        }
        obj_this.context.loading(0);
      }else{
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
      alert("Network error");
      obj_this.context.loading(0);
    });
    
  }

  render(){
    console.log(this.state.sale_return_total);

    return(
      <MyContext.Consumer>
        {context => (
        <div className="targets_page_container">
          <div className="page_header">
            <Link className="back_button" to="/dashboard"><i className="fas fa-arrow-left"></i></Link>
            <div className="page_title">Target</div>
          </div> 
          <form className="executive_district_form" onSubmit={this.formSubmit} 
         
          >
            <div className="blocks">
              <div className="input_grid">
                <div className="dist_exe"  style={{display:this.context.user_details.designation=='ASM'?"block":this.context.user_details.designation=='RSM'?"block":this.context.user_details.designation=='ADMIN'?"block":"none"}}>
                  <div className="input_label">District</div>
                  <Select 
                    options={this.state.districts}  
                    value ={this.state.district}
                    className="district" 
                    // ref={this.districtSelect}
                    onChange={e => this.districtChange(e)}
                    required
                  />
                  <div className="input_label">Executive</div>
                  <Select 
                    options={this.state.executives}  
                    value ={this.state.executive}
                    className="executive" 
                    // ref={this.exeSelect}
                    onChange={e => this.executiveChange(e)}
                    required
                  />
                </div>
                <div className="input_label">Month</div>
                  <DatePicker
                  selected={this.state.monthYear}
                  // value = {this.monthYear}
                  onChange={(date) => this.setMonthYear(date)}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  />
                <div className="submit_button_container">
                  <button className="submit_button">Submit</button>
                </div>
                </div>
                
            </div>
          </form>

          <div className="table_container">
            <table className="targets_table">
              <thead>
                <tr>
                  <th>Target</th>
                  <th align="right">{this.state.target_price}</th>
                </tr>
                <tr>
                  <th>Total Sales</th>
                  <th align="right">{this.state.total_sale}</th>
                </tr>
                <tr>
                  <th>Total Sales Return</th>
                  <th align="right">{this.state.sale_return_total}</th>
                </tr>
                <tr>
                  <th>Expiry Return Total</th>
                  <th align="right">{this.state.expiry_return_total}</th>
                </tr>
                <tr>
                  <th>Stock Return Total</th>
                  <th align="right">{this.state.stock_return_total}</th>
                </tr>
                <tr>
                  <th>Achieved</th>
                  <th  align="right">{this.state.achieved_price}</th>
                </tr>
                <tr>
                  <th>Pending</th>
                  <th  align="right">{this.state.pending_price}</th>
                </tr>
              </thead>
            </table>
          </div>
        </div>

      )}
    </MyContext.Consumer>
  )}
}

export default Targets;
Targets.contextType = MyContext;
