import React, { Component } from "react";
import MyContext from './MyContext.jsx';

export default class MyProvider extends Component {
  constructor(props){
    super(props);
    var user_details = window.localStorage.getItem('user_details_pharmacopius_emak_app');
    user_details = user_details?JSON.parse(user_details):"";
    this.state = {
      version : "1.4.8",
      user_details : user_details,
      show_loading : 0,
      location : {latitude:'', longitude:''},
    };
  }

  render() {
    return (
      <MyContext.Provider
          value={{
            version : this.state.version,
            user_details : this.state.user_details,
            setUserDetails : (data) => {
              this.setState({
                  user_details : data
              });
            },
            show_loading : this.state.show_loading,
            loading : (data) => {
              this.setState({
                  show_loading : data
              });
            },
            location : this.state.location,
            setLocation : (data) => {
              this.setState({
                location : data
              });
            }
          }}
      >
          {this.props.children}
      </MyContext.Provider>
    );
  }
}