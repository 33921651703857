import React, { Component } from "react";
// import {Link} from "react-router-dom";

import './Home.scss';
import MyContext from '../MyContext.jsx';
import { Link } from "react-router-dom";
import Select from "react-select";
import InfiniteScroll from 'react-infinite-scroll-component';



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sales_percentage: 0,
      collection_percentage: 0,
      sales_pending: 0,
      executive:'',
      executives:[],
      designations :[],
      designation :'',
      sales_achieved: 0,
      target_achieved : 0,
      sales_achieved_yearly : 0,
      sales_pending :  0,
      sales_percentage: 0,
      sales_target  : 0,
      current_month_sales: 0,
      sales_pending: 0,
      primary_sale  : 0,
      primary_sale_yearly : 0,
      crm_monthly  : 0,
      crm_yearly  : 0,
      settlement_monthly  : 0,
      settlement_yearly : 0, 
      total_outstanding : 0,
      total_overdue : 0,
      sr_monthly  : 0,
      sr_yearly : 0,
      sale_return_monthly :  0,
      sale_return_yearly  : 0,
      collection_from_tally_yearly : 0,
      collection_from_tally_monthly : 0,
      collection_from_tally_weekly : 0
    }
    this.getDashboardDetails = this.getDashboardDetails.bind(this);
    this.pulltorefresh = this.pulltorefresh.bind(this);
    this.getExecutives = this.getExecutives.bind(this);
    this.getDesignations = this.getDesignations.bind(this);
    this.executiveChange = this.executiveChange.bind(this);
    this.designationChange = this.designationChange.bind(this);




  }

  componentDidMount() {
    this.getDesignations();
    this.getDashboardDetails();

  }
  getDesignations(){
    var obj_this = this;
    const url = `${process.env.REACT_APP_SSS}/api/getDesignations`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         designations : return_data.designations,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }
  getExecutives(){
    var obj_this = this;
    if(!obj_this.state.designation.label)
      return;

    const url = `${process.env.REACT_APP_SSS}/api/getDesignationWiseExecutives`;
    $.ajax({
      url : url,
      data : {
        sid : this.context.user_details.sid,
        designation_id : obj_this.state.designation.value
      },
      type : "POST"
    }).done(function(return_data){
      console.log(return_data);
      if(return_data.status == "success"){
        obj_this.setState({
         executives : return_data.executives,
        });
      }else{
        obj_this.props.history.replace("/");
      }
    }).fail(function(){
        alert("Network error");
    });
  }

  getDashboardDetails(){
    const url = `${process.env.REACT_APP_SSS}/api/getDashboardDetails`;
    this.context.loading(1);
    var obj_this = this;
    $.ajax({
      url: url,
      data: {
        sid: this.context.user_details.sid,
        executive : this.state.executive.value
      },
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      if (return_data.status == "success") {
        obj_this.setState({
          sales_achieved: return_data.sales_achieved??'',
          target_achieved : return_data.target_achieved??'',
          sales_achieved_yearly : return_data.sales_achieved_yearly??'',
          sales_pending :  return_data.sales_pending??'',
          sales_percentage: return_data.sales_percentage??'',
          sales_target  : return_data.sales_target??'',
          current_month_sales: return_data.sales_achieved??'',
          sales_pending: return_data.sales_pending??'',
          location: return_data.location_mapped[0]??'',
          primary_sale  : return_data.primary_sale??'',
          primary_sale_yearly : return_data.primary_sale_yearly??'',
          crm_monthly  : return_data.crm_monthly??'',
          crm_yearly  : return_data.crm_yearly??'',
          settlement_monthly  : return_data.settlement_monthly??'',
          settlement_yearly : return_data.settlement_yearly??'', 
          total_outstanding : return_data.total_outstanding??'',
          total_overdue : return_data.total_overdue??'',
          sr_monthly  : return_data.sr_monthly??'',
          sr_yearly : return_data.sr_yearly??'',
          sale_return_monthly :  return_data.sale_return_monthly??'',
          sale_return_yearly  : return_data.sale_return_yearly,
          collection_from_tally_yearly : return_data.collection_from_tally_yearly,
          collection_from_tally_monthly : return_data.collection_from_tally_monthly,
          collection_from_tally_weekly : return_data.collection_from_tally_weekly

        })
        obj_this.context.loading(0);
      } else {
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function () {
      obj_this.context.loading(0);
      alert("Network error");
    });
  }

  designationChange(option){
    var obj_this = this;
    obj_this.setState({
      designation : option,
      executive : ''
    },()=>{
      obj_this.getExecutives();
    });

  }
  executiveChange(option){
    if(option.value == this.state.executive.value){
      return;
    }
    this.setState({
      executive : option
    })
    var obj_this = this;
    obj_this.context.loading(1);
    const url = `${process.env.REACT_APP_SSS}/api/getDashboardDetails`;

    $.ajax({
      url: url,
      data: {
        sid: this.context.user_details.sid,
        executive : option.value
      },
      type: "POST"
    }).done(function (return_data) {
      console.log(return_data);
      // return;
      if (return_data.status == "success") {
        obj_this.setState({
          sales_achieved: return_data.sales_achieved??'',
          target_achieved : return_data.target_achieved??'',
          sales_achieved_yearly : return_data.sales_achieved_yearly??'',
          sales_pending :  return_data.sales_pending??'',
          sales_percentage: return_data.sales_percentage??'',
          sales_target  : return_data.sales_target??'',
          current_month_sales: return_data.sales_achieved??'',
          sales_pending: return_data.sales_pending??'',
          location: return_data.location_mapped[0]??'',
          primary_sale  : return_data.primary_sale??'',
          primary_sale_yearly : return_data.primary_sale_yearly??'',
          crm_monthly  : return_data.crm_monthly??'',
          crm_yearly  : return_data.crm_yearly??'',
          settlement_monthly  : return_data.settlement_monthly??'',
          settlement_yearly : return_data.settlement_yearly??'', 
          total_outstanding : return_data.total_outstanding??'',
          total_overdue : return_data.total_overdue??'',
          sr_monthly  : return_data.sr_monthly??'',
          sr_yearly : return_data.sr_yearly??'',
          sale_return_monthly :  return_data.sale_return_monthly??'',
          sale_return_yearly  : return_data.sale_return_yearly,
          collection_from_tally_yearly : return_data.collection_from_tally_yearly,
          collection_from_tally_monthly : return_data.collection_from_tally_monthly,
          collection_from_tally_weekly : return_data.collection_from_tally_weekly

        })
        obj_this.context.loading(0);
      } else {
        obj_this.context.loading(0);
        obj_this.props.history.replace("/");
      }
    }).fail(function () {
      alert("Network error");
    });
  }
  pulltorefresh(){
    this.setState({
      executive:''
    })
    this.getDashboardDetails();
  }
  render() {
    return (
      <MyContext.Consumer>
        {context => (
          <div className="home_container">
            <div className="header_spacer"></div>
            <div className="home_dashboard_container uijs swipemenu">

              <div className="list_blocks_container">
              <InfiniteScroll
                  dataLength={1}
                  refreshFunction={this.pulltorefresh}
                  pullDownToRefresh
                  pullDownToRefreshThreshold={200}
                  pullDownToRefreshContent={
                    <h3 style={{ textAlign: 'center' , top : '50px'}}>&#8595; Pull down to refresh</h3>
                  }
                  releaseToRefreshContent={
                    <div className="loading_container" style={{ textAlign: 'center' , top : '100px'}} >
                      <div className="loading_symbol">
                        <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                        width="40px" height="40px" viewBox="0 0 50 50" style={{enableBackground:'new 0 0 50 50'}}>
                          <path fill="#000" d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
                             {/*<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="0.5s" repeatCount="indefinite"/>*/}
                          </path>
                        </svg>
                      </div>
                    </div>
                  }
                  >
                <div className="lists_container graph_block">
                  <div className="designation_header">
                    <div>
                      {this.context.user_details.designation ?? ''}
                    </div>
                    <div>
                      {
                        this.context.user_details.designation == 'FSM' ? this.state.location : null
                      }
                    </div>
                  </div>
                  { 
                  this.context.user_details.designation == 'ADMIN' || this.context.user_details.designation == 'RSM' || this.context.user_details.designation == 'ASM' ? 
                  <div className="exe_header">
                    <div className="exe_div">
                      {
                      <Select
                        className="exe_select"
                        options={this.state.designations}
                        value={this.state.designation}
                        onChange={this.designationChange}
                        isSearchable={false}
                        placeholder={<span>Designation</span>}
                      ></Select>
                      }
                    </div>
                    <div  className="exe_div">
                      {
                      <Select
                        className="exe_select"
                        options={this.state.executives}
                        value={this.state.executive}
                        onChange={this.executiveChange}
                        isSearchable={false}
                        placeholder={<span>Employees</span>}
                      ></Select>
                      }
                    </div>
                  </div>
                  :null} 
                 
                  <div className="yellow_shade1">
                    <div className="current_month">{new Date().toLocaleString('default', { month: 'long', day: 'numeric', year: 'numeric' })} </div>
                  </div>
                  <div className="yellow_shade2">
                    <div className="yellow_shade3"></div>
                    <div className="sale_target_container">
                      <table>
                        <thead>
                          <tr>
                          <th>
                            Target
                          </th>
                          <th>
                            Achieved
                          </th>
                          <th>
                            Pending
                          </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                           { this.state.sales_target} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                                <span>
                                  {this.state.sales_percentage}&nbsp;%
                                </span>
                                <br />
                                <br />
                                <span>
                                 {this.state.target_achieved}&nbsp;<i className="fas fa-rupee-sign"></i>
                                </span>
                            </td>
                            <td>
                                <span>
                                {100-this.state.sales_percentage}&nbsp;%
                                </span>
                                <br />
                                <br />
                                <span>
                                 {parseFloat(this.state.sales_pending)} <i className="fas fa-rupee-sign"></i>
                                </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  <div>
                    <div className="total_outstanding_container">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              Total Outstanding
                            </td>
                            <td>
                              <span>
                                {this.state.total_outstanding} <i className="fas fa-rupee-sign"></i>
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Total Overdue
                            </td>
                            <td>
                              <span>
                               {this.state.total_overdue} <i className="fas fa-rupee-sign"></i>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  <div className="collection_graph_container">
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>
                            &nbsp;
                            </th>
                            <th>
                              Current Month
                            </th>
                            <th>
                              Total Yearly
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              Primary
                            </td>
                            <td>
                              {this.state.primary_sale} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                             {this.state.primary_sale_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Secondary
                            </td>
                            <td>
                             {this.state.sales_achieved} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                            {this.state.sales_achieved_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              CRM
                            </td>
                            <td>
                              {this.state.crm_monthly} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                              {this.state.crm_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Settlement
                            </td>
                            <td>
                              {this.state.settlement_monthly} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                              {this.state.settlement_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Sample
                            </td>
                            <td>
                              {this.state.sr_monthly} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                            {this.state.sr_yearly}  <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Expiry
                            </td>
                            <td>
                             {this.state.sale_return_monthly} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                             {this.state.sale_return_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr>
                          {/* <tr>
                            <td>
                              Collection - Tally
                            </td>
                            <td>
                             {this.state.collection_from_tally_monthly} <i className="fas fa-rupee-sign"></i>
                            </td>
                            <td>
                             {this.state.collection_from_tally_yearly} <i className="fas fa-rupee-sign"></i>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>

                  </div>
                  <div className="yellow_shade2 margin_bottom">
                    {/* <div className="yellow_shade3"></div> */}
                    <div className="sale_target_container">
                      <table>
                        <thead>
                          <tr>
                          <th>
                            
                          </th>
                          <th>
                            Weekly
                          </th>
                          <th>
                            Monthly
                          </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                            Collection - Tally
                            </td>
                            <td>
                               
                                <span>
                                 {parseFloat(this.state.collection_from_tally_weekly)}&nbsp;<i className="fas fa-rupee-sign"></i>
                                </span>
                            </td>
                            <td>
                               
                                <span>
                                 {parseFloat(this.state.collection_from_tally_monthly)} <i className="fas fa-rupee-sign"></i>
                                </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                    </div>
                  </div>
                  <div>
                    <Link to='/pricelist'>
                      <button className="pushable">
                        {/* <span className="shadow"></span> */}
                        {/* <span className="edge"></span> */}
                        <span className="front">
                          Price List <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                        </span>
                      </button>
                    </Link>
                  </div>
                </div>
                </InfiniteScroll>
              </div>
            </div>
            <div className="bottom_navbar_spacer"></div>

          </div>
        )}
      </MyContext.Consumer>
    )
  }
}

export default Home;
Home.contextType = MyContext;
