import React from 'react';
import ReactDOM from 'react-dom';
import $ from 'jquery';
import '@fortawesome/fontawesome-free/css/all.min.css';

import './index.css';
import App from './App.jsx';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
import './assets/js/jquery_mods.js';
import './assets/js/ui.js';
import './assets/js/geo.js';
import './assets/scss/ui.scss';

const renderReactDom = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
};

if (window.cordova) {
  window.project_path = "/android_asset/www";
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  window.project_path = "";
  renderReactDom();
}
